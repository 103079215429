
<template v-slot:body>
  <b-row>
    <b-col lg="12" sm="12">
      <b-table-simple class="tg mt-3" hover small caption-top>
        <tbody>
          <tr>
            <th style="width: 15%"  class="text-right" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
            <th class="text-center" style="width: 5%">:</th>
            <td style="width: 30%" class="text-left">{{ formData.circular_memo_no }}</td>
            <th style="width: 15%" class="text-right" >{{ $t('elearning_config.fiscal_year') }}</th>
            <th class="text-center" style="width: 5%">:</th>
            <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
          </tr>
          <tr>
            <th style="width: 15%" class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('elearning_config.organization') }}</th>
            <th style="width: 5%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</th>
            <td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
            <th style="width: 15%"  class="text-right">{{ $t('elearning_config.office_type') }}</th>
            <th class="text-center" style="width: 5%">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</td>
          </tr>
          <tr>
            <th style="width: 15%"  class="text-right">{{ $t('globalTrans.office') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</td>
            <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_type') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
          </tr>
          <tr>
            <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_category') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
            <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_title') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
          </tr>
          <tr>
            <th style="width: 15%"  class="text-right">{{ $t('elearning_tpm.vanue') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.venue_bn : formData.venue }}</td>
            <th style="width: 15%"  class="text-right">{{ $t('elearning_tpm.training_quarter') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_quarter_bn : formData.training_quarter }}</td>
          </tr>
          <tr>
            <th style="width: 15%"  class="text-right">{{ $t('elearning_iabm.training_start_date') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ formData.training_start_date | dateFormat }}</td>
            <th style="width: 15%"  class="text-right">{{ $t('elearning_iabm.training_end_date') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ formData.training_end_date | dateFormat }}</td>
          </tr>
          <tr>
            <th style="width: 15%" class="text-right">{{ $t('elearning_tpm.no_of_days') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $n(formData.no_of_days) }}</td>
            <th style="width: 15%"  class="text-right">{{ $t('globalTrans.remarks') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.remarks_bn : formData.remarks }}</td>
          </tr>
        </tbody>
      </b-table-simple>
    </b-col>
  </b-row>
</template>
<script>

import flatpickr from 'flatpickr'
import { trainingElearningServiceBaseUrl, seedFertilizerServiceBaseUrl } from '@/config/api_config'
import ExportPdf from './export_pdf_details'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.course_managment')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 12, reportTitle, this.formData, this)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    }
  }
}
</script>
